export const LIMIT_MESSAGE = 20;

export const orderStatus = {
  REQUESTED: 'Requested',
  REJECTED: 'Rejected',
  ACCEPTED: 'Accepted',
  CONFIRMED: 'Confirmed',
  COMPLETED: 'Completed',
  CANCELED: 'Canceled',
  EXPIRED: 'Expired',
};

export const reviewType = {
  ATHLETE: 'Athlete',
  SHERPA: 'Sherpa',
};

export const Experience = ['Beginner', 'Intermediate', 'Expert'];

export const REASON = {
  DUPLICATE: 'duplicate',
  FRAUDULENT: 'fraudulent',
  REQUEST_BY_CUSTOMER: 'requested_by_customer',
};

export const PAYMENT_STATUS = {
  START: 'Start',
  SUCCEEDED: 'Succeeded',
  PARTIAL_REFUNDED: 'Partial refund',
  REFUNDED: 'Refunded',
};

export const GENDER = ['Male', 'Female', 'Non-binary'];

export const FORM_STATUS = {
  VALID: 'VALID',
  INVALID: 'INVALID',
};

export const STATUS = {
  ACTIVE: 1,
  INACTIVE: 2,
};

export const ACCOUNT_TYPE = {
  USER: 1,
  VERIFIED_USER: 2,
  BUSINESS_USER: 3,
  ADMIN: 4,
  SUPER_ADMIN: 5,
};

export const CATEGORY = ['Running', 'Walking', 'Cycling', 'Hiking', 'Personal Training', 'Golf', 'Tennis', 'Other'];

export enum Icons {
  addIcon = 'add-icon',
  closeIcon = 'close-icon',
  calendarIcon = 'calendar-icon',
  calendar50 = 'calendar50',
  miniusCircle = 'minius-circle',
  ordersIcon = 'orders-icon',
  message = 'message',
  location = 'location',
  locationOutline = 'location-outline',
  calendarOutline = 'calendar-outline',
  dash = 'dash',
  messages = 'messages',
  messages60 = 'messages60',
  subtract = 'subtract',
  attach = 'attach',
  start = 'start',
  startOutline = 'start-outline',
  star = 'star',
  star60 = 'star60',
  edit = 'edit',
  image = 'image',
  user = 'user',
  close = 'close',
  add = 'add',
  starOutline = 'star-outline',
  trashOutline = 'trash-outline',
  sherpa = 'sherpa',
  boxUser = 'box-user',
  mail = 'mail',
  phone = 'phone',
  state = 'state',
  running = 'running',
  char = 'char',
  way = 'way',
  userSerenity = 'user-serenity',
  copy = 'copy',
  runman = 'runman',
  search = 'search',
  history = 'history',
  MasterCard = 'MasterCard',
  Visa = 'Visa',
  JapanCreditBureau = 'JapanCreditBureau',
  EftposAustralia = 'EftposAustralia',
  Discover = 'Discover',
  DinersClub = 'DinersClub',
  ChinaUnionPay = 'ChinaUnionPay',
  AmericanExpress = 'AmericanExpress',
  back = 'back',
  check = 'check',
  note = 'note',
  checked = 'checked',
  athletePageLogo = 'athlete-page-logo',
  arrowLeft = 'arrow-left',
  arrowRight = 'arrow-right',
  gender = 'gender',
}
